<template>
  <div dir="ltr" class="h-100">
    <div class="h-100" style="display: flex; flex-direction: column">
      <div
        class="d-flex align-items-center justify-content-between"
        style="padding: 0 2% 0 1.5%"
      >
        <p class="m-0 TitleTop">Jump Logic</p>
        <div class="d-flex align-items-center">
          <svg
            width="94"
            height="60"
            viewBox="0 0 94 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="93"
              height="59"
              rx="7.5"
              fill="white"
            />
            <rect
              x="0.5"
              y="0.5"
              width="93"
              height="59"
              rx="7.5"
              stroke="#EAEAEA"
            />
            <rect x="4" y="4" width="70" height="15" rx="4" fill="#EEEEEF" />
            <rect x="4" y="23" width="70" height="15" rx="4" fill="#EEEEEF" />
            <rect x="4" y="42" width="70" height="15" rx="4" fill="#EEEEEF" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M85.4582 28.7637C85.3983 29.1036 85.0741 29.3306 84.7342 29.2706L79.8102 28.4024C79.4702 28.3425 79.2432 28.0183 79.3032 27.6784L80.1714 22.7543C80.2314 22.4144 80.5555 22.1874 80.8955 22.2474C81.2354 22.3073 81.4624 22.6315 81.4024 22.9714L80.814 26.3085C82.4424 25.0518 83.8595 23.8458 84.4764 21.9481C84.9373 20.5305 84.9249 19.0015 84.4413 17.5915C83.9576 16.1815 83.0289 14.9669 81.795 14.1307C81.5092 13.9371 81.4346 13.5484 81.6282 13.2627C81.8218 12.977 82.2105 12.9023 82.4962 13.0959C83.9545 14.0842 85.0521 15.5196 85.6237 17.1859C86.1952 18.8522 86.2098 20.6592 85.6652 22.3346C84.9118 24.652 83.1285 26.118 81.4275 27.4183L84.9513 28.0396C85.2912 28.0996 85.5182 28.4237 85.4582 28.7637Z"
              fill="#FFA202"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M85.4582 49.7637C85.3983 50.1036 85.0741 50.3306 84.7342 50.2706L79.8102 49.4024C79.4702 49.3425 79.2432 49.0183 79.3032 48.6784L80.1714 43.7543C80.2314 43.4144 80.5555 43.1874 80.8955 43.2474C81.2354 43.3073 81.4624 43.6315 81.4024 43.9714L80.814 47.3085C82.4424 46.0518 83.8595 44.8458 84.4764 42.9481C84.9373 41.5305 84.9249 40.0015 84.4413 38.5915C83.9576 37.1815 83.0289 35.9669 81.795 35.1307C81.5092 34.9371 81.4346 34.5484 81.6282 34.2627C81.8218 33.977 82.2105 33.9023 82.4962 34.0959C83.9545 35.0842 85.0521 36.5196 85.6237 38.1859C86.1952 39.8522 86.2098 41.6592 85.6652 43.3346C84.9118 45.652 83.1285 47.118 81.4275 48.4183L84.9513 49.0396C85.2912 49.0996 85.5182 49.4237 85.4582 49.7637Z"
              fill="#FFA202"
            />
          </svg>

          <div class="d-flex flex-column ml-2">
            <p
              class="m-0"
              style="
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                color: #000;
              "
            >
              How to use jump logic?
            </p>
            <a
              href="https://support.quizell.com/blogs/create-a-quiz/jump-logic?_pos=1&_sid=1a16fb3b1&_ss=r"
              target="_blank"
              class="helpLinkStyles mt-1"
              >Visit out help center</a
            >
          </div>
        </div>
      </div>

      <!-- Modal Content -->
      <div
        class="flex-grow-1"
        style="
          padding: 0 2% 0 1.5%;
          max-height: calc(100vh - 215px);
          overflow-y: scroll;
          overflow-x: hidden;
        "
      >
        <!-- Loader -->
        <div v-if="isJumpLogicLoading">
          <div class="mt-5">
            <div class="mb-4" v-for="index in 2" :key="index">
              <JumpLogicSkeleton />
            </div>
          </div>
        </div>

        <div v-else class="text-left mt-1">
          <!-- <div class="row"> -->
          <div
            class="mt-2 py-3 logic-div d-flex flex-column justify-content-center"
          >
            <div v-if="logics.length">
              <div
                class="row SingleLogicContainer text-left mb-3 mx-1"
                v-for="(createdLogic, index) in logics"
                :key="index"
              >
                <div class="col-11">
                  <div class="singleLogicDiv">
                    <div class="row">
                      <div
                        class="col-4 d-flex justify-content-around align-items-center"
                      >
                        <p class="conditionText m-0 mr-4">If</p>
                        <div
                          class="custom-select-input-wrapper w-100 position-relative"
                        >
                          <select
                            class="form-select w-100 form-control"
                            aria-label="Default select example"
                            v-model="createdLogic.questionId"
                            @change="createdLogic.optionIdArr = []"
                          >
                            <option selected disabled :value="null">
                              Select a question
                            </option>
                            <option
                              v-for="(pages, index) in questions"
                              :key="index"
                              :value="pages.id"
                            >
                              <div class="d-flex">
                                <LazyImage
                                  :src="pages.icon"
                                  alt=""
                                  style="width: 10px; height: 10px"
                                />

                                <span>{{ pages.name }}</span>
                              </div>
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-8 d-flex align-items-center">
                        <p class="conditionText m-0 mr-4">is equal to :</p>
                        <div
                          class="custom-select-input-wrapper w-50 OptionDropdown"
                        >
                          <multiselect
                            v-model="createdLogic.optionIdArr"
                            :options="
                              getSelectedQuestionOptions(
                                createdLogic.questionId
                              )
                            "
                            :key="createdLogic.questionId"
                            :multiple="true"
                            class="multiSelect"
                            :close-on-select="false"
                            :searchable="false"
                            label="option_value"
                            track-by="id"
                          >
                            <!-- label="option_value"
                                  track-by="id" -->
                          </multiselect>
                        </div>

                        <div class="OptionDropdown">
                          <select
                            v-if="
                              getQuestionType(createdLogic.questionId) ==
                              'multipleAnswer'
                            "
                            class="form-select mx-2 w-75 form-control"
                            aria-label="Default select example"
                            v-model="createdLogic.matchValue"
                          >
                            <option disabled :value="null">
                              Select an option
                            </option>
                            <option value="any">Any</option>
                            <option value="exact">Exact</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 px-5 mt-4 d-flex align-items-center"> -->
                    <div class="row mt-4">
                      <!-- LogicType -->
                      <div
                        class="col-2 d-flex justify-content-around align-items-center"
                      >
                        <div class="custom-select-input-wrapper w-100">
                          <select
                            class="form-select w-100 form-control"
                            aria-label="Default select example"
                            v-model="createdLogic.logicType"
                          >
                            <option
                              v-for="(logicType, index) in logicTypes"
                              :key="index"
                              :value="logicType.type"
                            >
                              {{ logicType.text }}
                            </option>
                            >
                          </select>
                        </div>
                      </div>

                      <div class="col-8 d-flex align-items-center">
                        <!-- Redirect Input Start -->
                        <div
                          class="custom-select-input-wrapper w-75"
                          v-if="
                            createdLogic.logicType == 'redirect' ||
                            createdLogic.logicType == 'redirect_self'
                          "
                        >
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Add redirect Link"
                              aria-label="Add redirect Link"
                              v-model="createdLogic.jumpMsgOrLink"
                            />
                          </div>
                        </div>
                        <!-- Redirect Input END -->
                        <!-- EndQuiz Input Start -->
                        <div
                          class="custom-select-input-wrapper w-75"
                          v-if="createdLogic.logicType == 'endQuiz'"
                        >
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Description"
                              aria-label="Description"
                              v-model="createdLogic.jumpMsgOrLink"
                            />
                          </div>
                        </div>
                        <!-- EndQuiz Input END -->

                        <!-- JumpTo Select Field Start -->
                        <div
                          class="custom-select-input-wrapper w-75"
                          v-if="createdLogic.logicType == 'jumpTo'"
                        >
                          <select
                            class="form-select w-100 form-control"
                            aria-label="Default select example"
                            v-model="createdLogic.jumpTo"
                          >
                            <option selected disabled :value="null">
                              Select a page
                            </option>
                            <option
                              v-for="(pages, index2) in toLogicPages"
                              :key="index2"
                              :value="{
                                id: pages.id,
                                value: pages.value,
                              }"
                            >
                              {{ pages.name }}
                            </option>
                          </select>
                        </div>
                        <!-- JumpTo Select Field End -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1">
                  <button
                    type="button"
                    class="btn deleteButton"
                    @click="deleteLogic(index, createdLogic)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z"
                        fill="#73738D"
                        fill-opacity="0.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div class="mt-1">
                <button
                  @click="createNewLogic"
                  class="btn outline-none border-none addBtn"
                >
                  <span v-if="!isCreatingLogic" style=""
                    ><svg
                      class="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.0833 7.58334H7.58329V11.0833H6.41663V7.58334H2.91663V6.41667H6.41663V2.91667H7.58329V6.41667H11.0833V7.58334Z"
                        fill="#1F1F28"
                      />
                    </svg>
                    ADD</span
                  >
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>

            <div v-else style="" class="d-flex align-items-center">
              <div
                style="border: 1px dashed #cccbcd"
                class="d-flex flex-column align-items-center justify-content-center w-100 py-5 emptyJumpLogicContainer"
              >
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="4"
                    y="4"
                    width="56"
                    height="56"
                    rx="28"
                    fill="#EADDF6"
                  />
                  <rect
                    x="4"
                    y="4"
                    width="56"
                    height="56"
                    rx="28"
                    stroke="#F4EFF9"
                    stroke-width="8"
                  />
                  <path
                    d="M40.75 29C40.75 28.5858 40.4142 28.25 40 28.25C39.5858 28.25 39.25 28.5858 39.25 29H40.75ZM40 35V35.75C40.4142 35.75 40.75 35.4142 40.75 35H40ZM34 34.25C33.5858 34.25 33.25 34.5858 33.25 35C33.25 35.4142 33.5858 35.75 34 35.75V34.25ZM22.1016 35.0152C21.9323 35.3933 22.1015 35.837 22.4795 36.0063C22.8576 36.1756 23.3013 36.0063 23.4706 35.6283L22.1016 35.0152ZM26.6461 31.1234L26.2833 30.467L26.6461 31.1234ZM32.2547 30.0878L32.1502 30.8305L32.2547 30.0878ZM39.25 29V35H40.75V29H39.25ZM40 34.25H34V35.75H40V34.25ZM23.4706 35.6283C24.2016 33.9959 25.4434 32.6452 27.009 31.7798L26.2833 30.467C24.4332 31.4897 22.9655 33.086 22.1016 35.0152L23.4706 35.6283ZM27.009 31.7798C28.5745 30.9145 30.3788 30.5813 32.1502 30.8305L32.3592 29.3451C30.2658 29.0506 28.1334 29.4444 26.2833 30.467L27.009 31.7798ZM32.1502 30.8305C35.1278 31.2494 37.0793 33.3867 39.4991 35.5582L40.5009 34.4418C38.2658 32.4361 35.9065 29.8442 32.3592 29.3451L32.1502 30.8305Z"
                    fill="#4D1B7E"
                  />
                </svg>

                <p
                  style="
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: center;
                  "
                >
                  No Jump Logic
                </p>
                <p
                  class="text-center"
                  style="color: #667085; font-weight: 400; font-size: 14px"
                >
                  You have not created any logic yet. <br />
                  Create now and start increasing your sales!
                </p>

                <Button class="px-5" @click="createNewLogic">
                  Create First Logic</Button
                >
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>

      <div
        class="p-2 d-flex justify-content-end align-items-center"
        style="
          border-top: 1px solid #eaecf0;
          background: #fff;
          box-shadow: 0px -4px 11px 0px rgba(31, 31, 40, 0.07);
        "
      >
        <button
          class="btn text-white mx-2 mt-2"
          style="
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            border-radius: 6px;
            background: #4d1b7e;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          "
          @click="saveData"
          :disabled="logics.length == 0 || !isLogicValid"
        >
          <span v-if="!JumpSpinner"> <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M14.697 8.03033C14.9899 7.73744 14.9899 7.26256 14.697 6.96967C14.4041 6.67678 13.9292 6.67678 13.6363 6.96967L14.697 8.03033ZM8.33332 13.3333L7.80299 13.8637C8.09588 14.1566 8.57075 14.1566 8.86365 13.8637L8.33332 13.3333ZM6.36361 10.303C6.07072 10.0101 5.59584 10.0101 5.30295 10.303C5.01006 10.5959 5.01006 11.0708 5.30295 11.3637L6.36361 10.303ZM13.6363 6.96967L7.80299 12.803L8.86365 13.8637L14.697 8.03033L13.6363 6.96967ZM5.30295 11.3637L7.80299 13.8637L8.86365 12.803L6.36361 10.303L5.30295 11.3637ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25Z" fill="white"/>
</svg>  Save Changes</span>
          <div v-else class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Button from "../../Dashboard/Layout/Button.vue";
export default {
  components: {
    Multiselect,
    Button,
    JumpLogicSkeleton: () =>
      import(/* webpackChunkName: "JumpLogic" */ "./JumpLogicSkeleton.vue"),
  },
  data() {
    return {
      isJumpLogicEdited: false,
      JumpSpinner: false,
      isJumpLogicLoading: false,
      isCreatingLogic: false,
      questions: [],
      sampleText: "test",
      logicTypes: [
        {
          id: 1,
          type: "jumpTo",
          text: "Jump To",
        },

        {
          id: 2,
          type: "endQuiz",
          text: "End Quiz",
        },
        {
          id: 3,
          type: "redirect",
          text: "Redirect New Tab",
        },
        {
          id: 4,
          type: "redirect_self",
          text: "Redirect Same Tab",
        },
      ],
      toLogicPages: [],
      logics: [],
    };
  },
  mounted() {
    this.JumpLogicData();
  },
  methods: {

    getQuestionType(id) {
      if (id) {
        let QuesType = null;
        this.questions.forEach((x) => {
          if (x.id == id) {
            QuesType = x.value;
          }
        });

        return QuesType;
      }
    },
    async createNewLogic() {
      this.isCreatingLogic = true;
      try {
        const data = {
          quizId: this.getQuizID,
        };
        const response = await axios.post(`/createNewLogic`, data);
        if (response.status == 200) {
          const obj = {};
          obj.id = response.data.data.id;
          obj.questionId = null;
          obj.optionId = null;
          obj.logicType = "jumpTo";
          obj.jumpTo = {};
          (obj.optionIdArr = []), (obj.jumpMsgOrLink = "");

          this.logics.push(obj);

          // this.logics = response.data.data.logics;
        } else {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.isCreatingLogic = false;
      }
    },
    getSelectedQuestionOptions(id) {
      if (id) {
        const getOptionsIndex = this.questions.findIndex((e) => e.id == id);
        if (getOptionsIndex > -1) {
          // let arr = this.questions[getOptionsIndex].options;
          let arr = this.questions[getOptionsIndex].question_options;
          return arr;
        }
        return [];
      }
      return [];
    },
    async JumpLogicData() {
      this.isJumpLogicLoading = true;
      try {
        const data = {
          quizId: this.getQuizID,
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/loadLogics`, data);
        if (response.status == 200) {
          this.logics = response.data.data.logics;
          this.questions = response.data.data.questions;
          this.toLogicPages = response.data.data.toLogicPages;
        }
      } catch (error) {
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.isJumpLogicLoading = false;
      }
    },
    async deleteLogic(index, logic) {
      try {
        let data = {
          id: logic.id,
          quizId: this.getQuizID,
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/removeLogic`, data);
        if (response.status == 200) {
          this.$toasted.show("Logic removed ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.logics.splice(index, 1);
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      }
    },
    async saveData() {
      const TotalLogics = this.logics;

      this.JumpSpinner = true;
      try {
        let data = {
          logics: JSON.stringify(TotalLogics),
          quizId: this.getQuizID,
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/saveLogics`, data);
        if (response.status == 200) {
          this.$toasted.show("Jump logic saved", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.isEdited = false;
          await this.JumpLogicData();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.JumpSpinner = false;
      }
    },
  },
  computed: {
    isLogicValid() {
  if (this.logics.length > 0) {
    return this.logics.every((item) => {
      if (item.logicType === 'jumpTo') {
        return item.optionIdArr && item.optionIdArr.length > 0 && item.questionId && item.jumpTo;
      } else {
        return (
          item.optionIdArr &&
          item.optionIdArr.length > 0 &&
          item.questionId &&
          item.jumpMsgOrLink &&
          item.jumpMsgOrLink.trim() !== ''
        );
      }
    });
  } else {
    return false;
  }
},
    ...mapGetters(["getQuizID"]),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.emptyJumpLogicContainer {
  background-image: url("https://images.quizell.com/website/jumpLogicBG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.multiselect__tags {
  border: 1px solid #4d1b7e !important;
  min-height: 45px !important;
  /* padding: 3px 40px 0 8px; */
  border-radius: 8px !important;
}
.multiselect__option--selected {
  background: #ffffff !important;
  color: #4d1b7e !important;
}
</style>
<style scoped>
.TitleTop {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #1f1f28;
}

.save {
  cursor: pointer;
}
.save:disabled {
  opacity: 40%;
  pointer-events: none;
}
.logic-div {
  width: 100%;
  min-height: 150px;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  /* background: #ffffff; */
}
.SingleLogicContainer {
  border-radius: 6px;
  background: rgba(179, 175, 182, 0.08);
  padding: 20px 10px;
}
.singleLogicDiv {
  border: none;
  border-radius: 24px;
}
.singleLogicDiv .form-control {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #4d1b7e;
  color: #73738d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 100%;  */
  height: 45px;
}
.singleLogicDiv .conditionText {
  color: #1f1f28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.OptionDropdown >>> .multiselect__select:before {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("https://images.quizell.com/gallery/jumpLogicChevron.svg");
  background-repeat: no-repeat;
  position: relative;
  right: 0;
  top: 40%;
  border: none !important;
  padding-right: 2.5rem;
}
.OptionDropdown >>> .multiselect--active .multiselect__select:before {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("https://images.quizell.com/gallery/jumpLogicChevron.svg");
  background-repeat: no-repeat;
  position: relative;
  right: 0;
  top: 20%;
  border: none !important;
  padding-right: 2.5rem;
}
.OptionDropdown >>> .multiselect__placeholder {
  color: #73738d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.OptionDropdown >>> .multiselect .multiselect__tag,
.OptionDropdown >>> .multiselect .multiselect__tag-icon:hover {
  background: #4d1b7e !important;
  color: #ffffff !important;
}
.OptionDropdown >>> .multiselect .multiselect__tag-icon:after {
  color: #ffffff !important;
}
.OptionDropdown >>> .multiselect .multiselect__option--highlight,
.OptionDropdown >>> .multiselect .multiselect__option--highlight:after {
  background: #4d1b7e !important;
  color: #ffffff !important;
}

.OptionDropdown .selectQuestionElement {
  border: 0.4px solid #4d1b7e;
  box-sizing: border-box;
  border-radius: 8px;
  color: #1f1f28;
  text-align: center;
  padding: 6px 0;
}

.addBtn {
  display: flex;
  padding: 11px 14px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid rgba(31, 31, 40, 0.5);
  color: #1f1f28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.deleteButton {
  display: inline-flex;
  padding: 6px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(115, 115, 141, 0.5);
}

.custom-select-input-wrapper .form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("https://images.quizell.com/gallery/jumpLogicChevron.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  /* background-size: 1em 1em;  */
  padding-right: 2.5rem; /* Adjust the padding to make room for the icon */
}
</style>
