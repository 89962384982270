<template>
  <div class="w-100" style="padding: 15px 10px">
    <section class="w-100 vendors" v-if="!isOpenVariant">
      <div
        class=""
        v-if="areNotAvailableVendors"
        style="height: 55vh; overflow-y: scroll; overflow-x: hidden"
      >
        <LoaderComp v-if="isLoadingImport" />
        <div
          v-else
          class="row question-not-font flex-column d-flex justify-content-center align-items-center"
        >
          <LazyImage
            :src="'/assets/img/noData.svg'"
            alt=""
            style="width: 15rem"
            class="mt-5"
          />
          <div class="row text-center px-2">
            <div class="col-12">
              <h3
                class="pt-5"
                style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 21px;

                  letter-spacing: 0.04em;

                  color: #4d4950;
                "
              >
                No Vendors found!
              </h3>
            </div>
            <!-- <div class="col-12">
            <h5 v-if="getUserType !='wix'">You can <a  href='javascript:void(0)' 
            @click="()=>{isLoadingImport=true; $emit('importItems',{title:'saveProductVendors',callBackFunction:()=>{isLoadingImport=false;}})}">
            import vendors</a> from your store</h5>
          </div> -->
          </div>
        </div>
      </div>
      <b-skeleton type="input" v-else-if="isLoading"></b-skeleton>
      <div class="" v-else>
        <div
          class="vendorSearchInput col-12 row align-items-center mx-1 pr-3 py-1"
        >
          <div style="cursor: pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z" fill="#73738D"/>
</svg>
            <input
              type="text"
              class="mx-2 flex-grow-1"
              v-model="searchCollection"
              placeholder="Search for Vendor"
            />
          </div>
        </div>
      
      </div>

      <div v-if="isLoading" class="col-12 mt-4 d-flex justify-content-end">
        <b-skeleton class="w-25"></b-skeleton>
      </div>
      <div v-else class="col-12 mt-4 d-flex justify-content-end">
        <div
          class="filterBtn"
          v-if="!areNotAvailableVendors && getVendors.length > 0"
          @click.stop="bulkSelectVendors"
        >
          {{ isSelectAll ? "Select All" : "Unselect All" }}
        </div>
      </div>
      <div class="w-100 p-3 vendorBox" v-if="isLoading" style="border: none">
        <div class="skel">
          <div class="mb-2 py-1" v-for="index in 5" :Key="index">
            <div class="d-flex align-items-center">
              <b-skeleton type="avatar" animation="throb"></b-skeleton>
              <b-skeleton
                type="input"
                class="ml-2 w-86"
                animation="throb"
              ></b-skeleton>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div
        class="w-100 p-3 vendorBox"
        v-if="!areNotAvailableVendors && getVendors.length > 0"
        style="border: none"
      >
        <div
          :class="[
            isSelectedProduct(vendor) ? 'selectedProduct' : '',
            'mb-2 py-1,vendor-row',
          ]"
          v-for="(vendor, prodIndex) in getVendors"
          :key="vendor.id"
        >
          <div class="d-flex align-items-center vendor">
            <div
              class="d-flex px-0 align-items-center"
              @click="selectProduct(vendor)"
              style="flex: 0.9; cursor: pointer"
            >
              <div class="">
                <div class="custom-control custom-checkbox" role="group">
                  <input
                    type="checkbox"
                    :id="`quest-${prodIndex}`"
                    class="custom-control-input position-static"
                    aria-label="Never"
                    :value="{
                      product_vendor_id: vendor.id,
                      question_option_id:
                        question.options[selected.optionIndex].id,
                    }"
                    v-model="
                      question.options[selected.optionIndex].selected_vendors
                    "
                    @change="selectProduct(vendor)"
                  />
                  <label
                    :for="`quest-${prodIndex}`"
                    class="custom-control-label"
                  ></label>
                </div>
              </div>

              <div class="singleProduct d-flex align-items-center pl-2">
                <p class="title m-0 py-2 text-left">
                  {{ vendor.title }}
                </p>
              </div>
            </div>
            <div class="text-center" style="flex: 0.1">
              <button
                v-b-tooltip.hover
                title="Products"
                class="btn"
                @click.stop="openVariant(vendor.title)"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 19H13L11 21H4C3.5 21 2.97 20.79 2.59 20.41C2.21 20.03 2 19.5 2 19V9H4V19ZM17.63 5.84C17.27 5.33 16.67 5 16 5H8C6.9 5 6 5.9 6 7V15C6 16.1 6.9 17 8 17H16C16.67 17 17.27 16.66 17.63 16.15L22 11L17.63 5.84Z"
                    fill="#4D4950"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-100 p-3 vendorBox"
        v-if="!this.isLoading && vendors.length > 0 && getVendors <= 0"
        style="border: none"
      >
        <div
          class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1"
        >
          <div
            class="btn"
            v-b-tooltip.hover.top="'Reset Filter'"
            @click="searchCollection = ''"
          >
            <i class="fa-solid fa-arrow-rotate-right"></i>
          </div>
          <h5>No vendors were found matching your filter</h5>
        </div>
      </div>
    </section>
    <vendor-variant
      v-else
      @backFromVariant="backFromVariant()"
      :isLoadingProducts="isLoadingCollectionProducts"
      :VendorProducts="vendorProducts"
      :selected="selected"
    ></vendor-variant>
  </div>
</template>
<script>
import LoaderComp from "../../component/LoaderComp.vue";
import VendorVariant from "./VendorVariant.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    vendors: Array,
    selected: Object,
    question: Object,
    isLoading: Boolean,
    products: Array,
  },
  components: {
    VendorVariant,
    LoaderComp,
  },
  computed: {
    areNotAvailableVendors() {
      return this.isLoading == false && this.vendors.length <= 0 ? true : false;
    },
    ...mapGetters([
      "getEditorSettings",
      "GetTokenFromMetaVendor",
      "getQuizID",
      "getNavbarData",
    ]),

    getUserType() {
      return this.getNavbarData.userType;
    },

    getSortedVendors() {
      if (
        this.question.options[this.selected.optionIndex].selected_vendors.length
      ) {
        let sortedVendors = [];

        //  find Selected Products from All Products
        this.question.options[
          this.selected.optionIndex
        ].selected_vendors.forEach((selectedSingleVendor) => {
          const selectedVendors = this.vendors.find(
            (vendor) => vendor.id === selectedSingleVendor.product_vendor_id
          );
          if (selectedVendors) {
            sortedVendors.push(selectedVendors);
          }
        });

        // Find remaining non selected Products
        const otherVendors = this.vendors.filter(
          (vendor) =>
            !this.question.options[
              this.selected.optionIndex
            ].selected_vendors.some(
              (selectedSingleVendor) =>
                selectedSingleVendor.product_vendor_id === vendor.id
            )
        );
        sortedVendors = sortedVendors.concat(otherVendors);

        return sortedVendors;
      } else {
        return this.vendors;
      }
    },
    getVendors() {
      return this.getSortedVendors.filter((item) => {
        return item.title
          .toLowerCase()
          .includes(this.searchCollection.toLowerCase());
      });
    },
  },
  data() {
    return {
      isLoadingImport: false,
      isLoadingBtn: false,
      isOpenVariant: false,
      skeletonCount: 5,
      searchCollection: "",
      isSelectAll: true,
      showResetBtn: false,
      vendorProducts: [],
      isLoadingCollectionProducts: false,
    };
  },
  methods: {
    async openVariant(title) {
      this.isLoadingCollectionProducts = true;
      this.isOpenVariant = true;
      try {
        this.vendorProducts = this.products.filter(
          (product) =>
            product.vendor &&
            product.vendor.trim().toLowerCase() == title.trim().toLowerCase()
        );
      } catch (error) {
        if (error) {
          this.$toasted.show("Opps, something went wrong! ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
      }
      // await axios.post('previewProductVariants',{vendor:title}).then((response)=>{
      // this.vendorProducts=response.data.data
      // }).catch(()=>{
      //   this.$toasted.show("Error occurred ", {
      //     theme: "toasted-primary",
      //     type: "error",
      //     position: "bottom-center",
      //     duration: 2000,
      //   });
      // })
      this.isLoadingCollectionProducts = false;
    },
    backFromVariant() {
      this.isOpenVariant = false;
    },
    bulkSelectVendors() {
      let vendors = this.getVendors;
      if (this.isSelectAll) {
        this.isSelectAll = false;

        vendors.forEach((vendor) => {
          let checkDuplicate = {
            product_vendor_id: vendor.id,
            question_option_id:
              this.question.options[this.selected.optionIndex].id,
          };
          if (
            !this.question.options[
              this.selected.optionIndex
            ].selected_vendors.includes(checkDuplicate)
          ) {
            this.question.options[
              this.selected.optionIndex
            ].selected_vendors.push({
              product_vendor_id: vendor.id,
              question_option_id:
                this.question.options[this.selected.optionIndex].id,
            });
          }
        });
      } else {
        this.question.options[this.selected.optionIndex].selected_vendors = [];
        this.isSelectAll = true;
      }
    },

    isSelectedProduct(vendor) {
      const findIndex = this.question.options[
        this.selected.optionIndex
      ].selected_vendors.findIndex((x) => x.product_vendor_id == vendor.id);
      if (findIndex > -1) {
        return true;
      } else {
        return false;
      }
    },

    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    vendorImage(image) {
      if (image) return image.startsWith("upload") ? "/" + image : image;
      return "https://images.quizell.com/default/default_image.png";
    },
    selectProduct(vendor) {
      // question.options[selected.optionIndex].selected_products
      const index = this.question.options[
        this.selected.optionIndex
      ].selected_vendors.findIndex(
        (productOption) => productOption.product_vendor_id == vendor.id
      );

      if (index > -1) {
        this.question.options[
          this.selected.optionIndex
        ].selected_vendors.splice(index, 1);
      } else {
        const obj = {};
        obj.product_vendor_id = vendor.id;
        obj.question_option_id =
          this.question.options[this.selected.optionIndex].id;

        this.question.options[this.selected.optionIndex].selected_vendors.push(
          obj
        );
      }
    },
  },
};
</script>
<style>
.vendor-image {
  height: 50px;
  width: 50px;
  /* border: 4px solid rgb(255, 255, 255);  */
  border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  padding: 0.25rem;
  background-color: #fff;
}

.vendor-icon {
  color: #4d1b7e;
}

.vendor-icon:hover {
  color: black;
}



.vendorSearchInput {
  border: 0.8px solid #18191C14;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.vendorSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.vendorSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer;
}

.filterBtn:hover {
  color: black;
}

.w-86 {
  width: 86%;
}

.vendorBox {
  height: 50vh;
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.vendorBox .vendor {
  border-radius: 6px;
}

.vendorBox .vendor:hover {
  background: #b3afb614;
}

.vendor-btn {
  display: none;
  font-size: 14px;
  border: 1px solid #b3afb6;
  border-radius: 12px;
  outline: none;
}

.selectedProduct .vendor:hover .vendor-btn {
  border: 1.5px solid #4d1b7e;
  color: #4d1b7e !important;
}
.selectedProduct .vendor:hover .vendor-btn .variant-text {
  color: #4d1b7e !important;
}
.selectedProduct .vendor:hover .vendor-btn .vendor-text {
  color: #4d1b7e !important;
}

.vendor:hover .vendor-btn {
  display: block;
}

.singleProduct .title {
  color: #18191c;
  font-weight: 500;
}

.singleProduct .price {
  color: #4d4950;
  font-weight: 300;
}
</style>
