<template>
  <div class="SingleLogicContainer">
    <div class="header-section d-flex ">
      <div
        class="integrationImgae bg-preload"
        style="height: 50px; width: 400px; border-radius: 14px"
      >
        <div class="into-preload"></div>
      </div>
      <div
        class="integrationImgae bg-preload ml-5"
        style="height: 50px; width: 500px; border-radius: 14px"
      >
        <div class="into-preload"></div>
      </div>
      
    </div>
    <div class="mid-section d-flex pt-4 pb-3">
      <div
        class="integrationImgae bg-preload"
        style="height: 50px; width: 400px; border-radius: 14px"
      >
        <div class="into-preload"></div>
      </div>
      <div
        class="integrationImgae bg-preload ml-5"
        style="height: 50px; width: 500px; border-radius: 14px"
      >
        <div class="into-preload"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.SingleLogicContainer {
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 14px;
  box-shadow: 0px -2px 16px rgba(77, 27, 126, 0.1);
}
</style>
